<template>
  <v-container fluid pa-0 ma-0>
    <v-layout row wrap max-width-1400 full-width ma-auto>
      <v-flex xs12 py-5>
        <p
          class="graphik-medium secondary--text my-3 text-xs-center"
          :class="{'font-28': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('content_reviews_title') }}</p>

        <swiper :options="swiperOptions">
          <swiper-slide v-for="(review, index) in reviews" :key="index">
            <v-card class="my-2 max-width-350 ma-auto">
              <v-card-text class="pa-0">
                <v-layout wrap align-space-around max-height-250 min-height-250 pa-4>
                  <v-flex xs12>
                    <p class="graphik-bold font-20 black--text max-width-300">
                      {{ review.title }}
                    </p>
                  </v-flex>

                  <v-flex xs12>
                    <p class="graphik-light font-16 max-width-300">
                      {{ review.description }}
                    </p>
                  </v-flex>

                  <v-flex xs12 d-flex align-end>
                    <v-rating :value="5" dense readonly color="#F7B500"></v-rating>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </swiper-slide>
        </swiper>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'ContentCompanies',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: this.$vuetify.breakpoint.smAndUp ? (this.$vuetify.breakpoint.mdAndUp ? 3 : 2) : 1,
        loop: true,
        spaceBetween: this.$vuetify.breakpoint.smAndUp ? 20 : 0,
        centeredSlides: false,
        watchSlidesVisibility: true,
        navigation: {
          nextEl: '.next',
          prevEl: '.prev'
        },
        autoplay: {
          delay: 3000
        }
      },
      reviews: [
        {
          title: 'Andrés J',
          description: 'Super rápida y eficiente pagina. Me metí para comprar el seguro de mi coche, me dieron varias opciones y yo elegí la que me gusto más y ya. Excelente Servicio.'
        },
        {
          title: 'Sylvia G.',
          description: 'Excelente servicio y justo lo que yo necesitaba me lo ofrecieron. Super buena opción y muy recomendable.'
        },
        {
          title: 'Lylly O.',
          description: 'Me explican todas mis dudas, buen trato y los mejores precios y coberturas.. ¡100% recomendables!'
        },
        {
          title: 'Gabriela V.',
          description: 'La forma más fácil de adquirir un seguro de auto! Excelente atención, servicio, variedad de soluciones y tiempo de respuesta.'
        },
        {
          title: 'Jeannette G.',
          description: 'Excelente servicio, muy al pendiente del asegurado..'
        }
      ]
    }
  }
}
</script>
